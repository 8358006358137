html {
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
}

body {
  font-family: "Roboto";
  margin: 0;
}

* {
  box-sizing: border-box;
}

a:link {
  text-decoration: none;
}
